.home-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.carousel,
.carousel-item,
.carousel-item img {
  height: 100vh;
}

.carousel-item img {
  object-fit: cover;
  justify-content: center;
  align-items: center;
}

.contact-section {
  position: absolute;
  bottom: 10px;
  right: 10px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust the gap between elements as needed */
  z-index: 15;
}

.contact-section p {
  margin: 0;
  line-height: 1.5;
}

.contact-section a svg {
  width: 25px;
  height: 25px;
}

p{
  font-family: "Satisfy", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 25px;
}