.bill-input {
    margin-bottom: 20px;
    width: 100%;
  }
  
  .bill-input label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .bill-input input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
  }