.navbar-container {
  position: absolute;
  top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
  overflow-x: auto;
  white-space: nowrap;
}

.navbar {
  display: flex;
  gap: 10px;
  padding: 10px;
}

.navbar button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  white-space: nowrap;
}

.navbar button:hover {
  background-color: #0056b3;
}