.reset-button {
    margin-bottom: 20px;
    width: 100%;
  }
  
  .reset-button button {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #00796b;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
  
  .reset-button button:hover {
    background-color: #004d40;
  }