.tip-selector {
    margin-bottom: 20px;
    width: 100%;
  }
  
  .tip-selector label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .tip-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .tip-buttons button {
    flex: 1;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #00796b;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
  
  .tip-buttons button:hover {
    background-color: #004d40;
  }